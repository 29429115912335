import './Sending.css'

const Sending = ()=>{
  return(
    <div className='Sending'>
      <p>Sending Email</p>
    </div>
  )
}

export default Sending